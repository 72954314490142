import { Firestore } from "@firebase/firestore";
import {
  CategoryOutlined,
  DescriptionOutlined,
  HistoryEduOutlined,
} from "@mui/icons-material";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Description from "@mui/icons-material/Description";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RefreshIcon from "@mui/icons-material/Refresh";
import SourceIcon from "@mui/icons-material/SourceOutlined";
import {
  Box,
  Breadcrumbs,
  Button,
  IconButton,
  LinearProgress,
  Link,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import axios from "axios";
import { User } from "firebase/auth";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import BlogCategory from "./BlogCategory";
import BlogDialog from "./BlogDialog";
import LoadingScreen from "./LoadingScreen";
import ProjectDialog from "./ProjectDialog";
import ReportViewer from "./ReportViewer";
import { Company } from "./types";
import { hostname, parseError } from "./utils";

export default ({
  db,
  currentUser,
  companyId,
  token,
  getToken,
  eventListener,
  openDialog,
  documentId,
  setTab,
}: {
  db: Firestore;
  currentUser: any | User;
  companyId: string;
  token: any | string;
  getToken: Function;
  eventListener: Function;
  openDialog: Function;
  documentId: any | string;
  setTab: Function;
}) => {
  const [init, setInit] = useState(false);
  const [blogs, setBlogs] = useState<Array<any>>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [loading, setLoading] = useState(true);
  const [fullWidthDialog, setFullWidthDialog] = useState(true);
  const [company, setCompany] = useState<Company>({
    uid: "",
    id: "",
    name: "",
    type: "",
    description: "",
    employees: [],
    website: "",
  });
  const navigate = useNavigate();
  const [path, setPath] = useState("/");
  const [refreshing, setRefreshing] = useState(false);
  const [openButton, setOpenButton] = useState("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [employees, setEmployees] = useState([]);
  const [createBlogDialogOpen, setCreateBlogDialogOpen] = useState(false);
  const [createCategoryOpen, setCreateCategoryOpen] = useState(false);
  const [currentBlog, setCurrentBlog] = useState<any>({});
  const [reportViewerOpen, setReportViewerOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState<any>({ report: "" });
  const [createArticleOpen, setCreateArticleOpen] = useState(false);

  async function getData(folderPath: string) {
    setInit(true);
    setLoading(true);
    const currentToken = await getToken();
    axios
      .get(`${hostname}/companies/${companyId}`, {
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      })
      .then((res) => {
        setCompany(res.data.company);
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
    axios
      .get(`${hostname}/companies/${companyId}/employees`, {
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      })
      .then((res) => {
        setEmployees(res.data.employees);
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
    await axios
      .get(`${hostname}/blogs`, {
        headers: { Authorization: `Bearer ${currentToken}` },
        params: { path: folderPath, companyId: companyId },
      })
      .then((res) => {
        setBlogs(res.data);
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
    setLoading(false);
  }

  const onCreatePrject = async (project: any) => {
    console.log("Created project", project);
    setRefreshing(true);
    const currentToken = await getToken();
    axios
      .post(
        `${hostname}/blogs/article`,
        {
          project: project,
          path: path,
          companyId: companyId,
          blog: currentBlog,
        },
        {
          headers: { Authorization: `Bearer ${currentToken}` },
        }
      )
      .then((res) => {
        return refreshData(path);
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      })
      .finally(() => {
        setRefreshing(false);
      });
  };

  const refreshData = async (folderPath: string) => {
    setRefreshing(true);
    const currentToken = await getToken();
    axios
      .get(`${hostname}/blogs`, {
        headers: { Authorization: `Bearer ${currentToken}` },
        params: { path: folderPath, companyId: companyId },
      })
      .then((res) => {
        setBlogs(res.data);
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      })
      .finally(() => {
        setRefreshing(false);
      });
  };

  const deleteDocument = async (d: any) => {
    setRefreshing(true);
    const currentToken = await getToken();
    await axios
      .delete(`${hostname}/blogs/${d.id}`, {
        headers: { Authorization: `Bearer ${currentToken}` },
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
    await refreshData(path);
  };

  const deleteFolder = async (folder: any) => {
    const currentToken = await getToken();
    const res = await axios.delete(`${hostname}/blogs/folders/` + folder.id, {
      headers: { Authorization: `Bearer ${currentToken}` },
    });
    const files = res.data.length;
    openDialog(
      "Delete Folder",
      `Are you sure you want to delete ${files} articles/categories?`,
      "Confirm",
      () => () => {
        setRefreshing(true);
        axios
          .delete(`${hostname}/blogs/folders/` + folder.id, {
            headers: { Authorization: `Bearer ${currentToken}` },
            params: { delete: true },
          })
          .then(() => {
            refreshData(path);
          })
          .catch((err) => {
            eventListener({
              type: "SET_ERROR",
              error: parseError(err),
            });
          });
      }
    );
    return res;
  };

  const openArticle = async (articleId: string) => {
    const currentToken = await getToken();
    await axios
      .get(`${hostname}/blogs/${articleId}`, {
        params: {
          companyId,
        },
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      })
      .then((res) => {
        console.log(res);
        setSelectedProject(res.data.project);
        setReportViewerOpen(true);
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
  };

  const base64ToBlob = (base64: string, mimeType: string) => {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeType });
  };

  const downloadPdf = async (projectId: string) => {
    const currentToken = await getToken();
    axios
      .get(`${hostname}/projects/${projectId}/download/pdf`, {
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      })
      .then((res) => {
        const blo = base64ToBlob(res.data.b64, res.data.mimeType);
        const url = window.URL.createObjectURL(blo);
        const a = document.createElement("a");
        a.href = url;
        a.download = res.data.filename; // Specify the file name
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
  };

  if (currentUser.uid && !init) {
    getData(path);
  }

  const getPathBreadcrumbs = () => {
    if (path == "/") {
      return <link></link>;
    }
    return path
      .split("/")
      .filter((x) => x)
      .map((p, i) => (
        <Link
          component="button"
          key={p}
          underline="hover"
          color="inherit"
          onClick={() => {
            const currentPath = path
              .split("/")
              .slice(0, path.split("/").indexOf(p) + 1)
              .join("/");
            setPath(currentPath);
            refreshData(currentPath);
          }}
        >
          <span>{p}</span>
        </Link>
      ));
  };

  if (loading) {
    return <LoadingScreen open={loading} />;
  }

  return (
    <Box>
      <Button
        sx={{ mt: 2, display: path !== "/" ? "none" : "" }}
        onClick={() => setCreateBlogDialogOpen(true)}
      >
        <HistoryEduOutlined sx={{ mr: 0.3 }} /> Create Blog
      </Button>
      <Button
        sx={{ mt: 2, ml: 2, display: path === "/" ? "none" : "" }}
        onClick={() => setCreateCategoryOpen(true)}
      >
        <CategoryOutlined sx={{ mr: 0.3 }} />
        Create Category
      </Button>

      <Button
        sx={{ mt: 2, ml: 2, display: path === "/" ? "none" : "" }}
        onClick={() => setCreateArticleOpen(true)}
      >
        <DescriptionOutlined sx={{ mr: 0.3 }} />
        Create Article
      </Button>

      <Typography sx={{ mt: 2 }}>
        Blogs is a beta feature with limited functionality at the moment. Please
        contact us if you have any questions or feedback.
      </Typography>

      <Paper style={{ marginTop: 20, marginBottom: 40 }} variant="outlined">
        {refreshing && <LinearProgress />}
        {!refreshing && <div style={{ height: 4 }}></div>}
        <div style={{ display: "flex" }}>
          <Breadcrumbs
            aria-label="breadcrumb"
            style={{ marginLeft: 10, marginTop: 8, flexGrow: 1 }}
          >
            <Link
              underline="hover"
              color="inherit"
              component="button"
              onClick={() => {
                setPath("/");
                refreshData("/");
              }}
            >
              Root
            </Link>
            {getPathBreadcrumbs()}
          </Breadcrumbs>
          <IconButton
            onClick={() => refreshData(path)}
            style={{ float: "right", marginRight: 3 }}
          >
            <RefreshIcon />
          </IconButton>
        </div>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell component="th" scope="row" sortDirection="desc">
                  <TableSortLabel active={true} direction="desc">
                    Name
                  </TableSortLabel>
                </TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Created</TableCell>
                <TableCell>Updated</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {blogs.map((row) => (
                <TableRow
                  key={row.id}
                  onClick={() => {
                    if (row.type === "blog" || row.type === "category") {
                      if (path == "/") {
                        setCurrentBlog(row);
                        setPath(path + row.name);
                        refreshData(path + row.name);
                      } else {
                        setPath(path + "/" + row.name);
                        refreshData(path + "/" + row.name);
                      }
                    } else {
                      openArticle(row.id);
                    }
                  }}
                >
                  <TableCell>
                    {row.type === "blog" && (
                      <HistoryEduOutlined style={{ marginRight: 10 }} />
                    )}
                    {row.type === "category" && (
                      <SourceIcon style={{ marginRight: 10 }} />
                    )}
                    {row.type === "article" && (
                      <Description style={{ marginRight: 10 }} />
                    )}
                  </TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>
                    {row.type
                      ? row.type[0].toUpperCase() +
                        row.type.substring(1, row.type.length)
                      : ""}
                  </TableCell>
                  <TableCell>{row.created}</TableCell>
                  <TableCell>{row.updated}</TableCell>
                  <TableCell align="right">
                    {row.type !== "article" && (
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteFolder(row);
                        }}
                      >
                        <DeleteOutline />
                      </IconButton>
                    )}
                    {row.type === "article" && (
                      <span>
                        <IconButton
                          onClick={(e: any) => {
                            e.stopPropagation();
                            setOpenButton(row.id);
                            setAnchorEl(e.currentTarget);
                          }}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          anchorEl={anchorEl}
                          open={row.id === openButton}
                          onClose={(e: any) => {
                            e.stopPropagation();
                            setOpenButton("");
                            setAnchorEl(null);
                          }}
                        >
                          <MenuItem
                            onClick={(e) => {
                              e.stopPropagation();
                              openArticle(row.id);
                            }}
                          >
                            View
                          </MenuItem>
                          <MenuItem
                            onClick={(e) => {
                              e.stopPropagation();
                              deleteDocument(row);
                            }}
                          >
                            Delete
                          </MenuItem>
                        </Menu>
                      </span>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <BlogDialog
        open={createBlogDialogOpen}
        onClose={() => {
          setCreateBlogDialogOpen(false);
          refreshData(path);
        }}
        getToken={getToken}
        eventListener={eventListener}
        companyId={companyId}
      />
      <BlogCategory
        open={createCategoryOpen}
        onClose={() => {
          setCreateCategoryOpen(false);
          refreshData(path);
        }}
        getToken={getToken}
        eventListener={eventListener}
        companyId={companyId}
        currentBlog={currentBlog}
        path={path}
      />
      <ReportViewer
        eventListener={eventListener}
        open={reportViewerOpen}
        onClose={() => setReportViewerOpen(false)}
        id={selectedProject.id}
        content={selectedProject.report}
        title={selectedProject.name}
        downloadPdf={downloadPdf}
      />
      <ProjectDialog
        title="Create Article"
        articleOnly
        onCreateProject={onCreatePrject}
        open={createArticleOpen}
        onClose={() => {
          setCreateArticleOpen(false);
          refreshData(path);
        }}
        eventListener={eventListener}
        getToken={getToken}
        company={company}
      />
    </Box>
  );
};
